import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Year,
  StyledSlider,
  SliderContainer,
  Container,
} from './BrandStoryStyled';
import Arrow from '../../components/Slider/Arrow';
import Img from 'gatsby-image';

import ArrowNextImage from '../../assets/images/aboutUs/arrow-next.png';
import ArrowPrevImage from '../../assets/images/aboutUs/arrow-prev.png';
import PageTitle from '../../components/PageTitle/PageTitle';

const Header = styled.h1`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 40px 0 0;
  color: #fff;
`;
const BottomYear = styled.p`
  margin-top: 20px;
  font-size: 36px !important;
  text-align: center;
  color: #ffffff;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
`;

const BrandStoryMb = ({ images, sliderRef }) => {
  const { t } = useTranslation();

  const Box = ({ year = null, text = null, image, bottomYear = true }) => {
    return (
      <div>
        {year !== null && <Year>{year}</Year>}
        <Img fluid={image} style={{ width: '100%' }} />
        {text !== null && <p dangerouslySetInnerHTML={{ __html: text }} />}
        {bottomYear && (
          <BottomYear>{year === null ? bottomYear : year}</BottomYear>
        )}
      </div>
    );
  };

  return (
    <>
      <PageTitle shadow={false}>{t('brand_story')}</PageTitle>
      <Container>
        <Header>THE HERITAGE</Header>

        <SliderContainer>
          <StyledSlider
            sliderRef={sliderRef}
            settings={{
              prevArrow: <Arrow type="prev" image={ArrowPrevImage} />,
              nextArrow: <Arrow type="next" image={ArrowNextImage} />,
              speed: 800,
              dots: false,
              arrows: true,
              adaptiveHeight: true,
            }}
          >
            <div className="position-relative">
              <Year>1952</Year>
              <div className="row no-gutters justify-content-between">
                <Img fluid={images['year-1952-1']} style={{ width: '30%' }} />
                <Img fluid={images['year-1952-2']} style={{ width: '67%' }} />
                <p>{t('about_1952_1')}</p>

                <Img fluid={images['year-1952-3']} style={{ width: '30%' }} />
                <p style={{ width: '67%' }}>{t('about_1952_2')}</p>
              </div>
              <BottomYear>1952</BottomYear>
            </div>
            <Box
              year={1957}
              image={images['year-1957-1']}
              text={t('about_1957_1')}
            />
            <div className="position-relative">
              <Year>1961</Year>
              <div className="row no-gutters justify-content-between">
                <Img fluid={images['year-1961-1']} style={{ width: '31%' }} />
                <Img fluid={images['year-1961-2']} style={{ width: '31%' }} />
                <Img fluid={images['year-1961-3']} style={{ width: '31%' }} />
              </div>
              <p dangerouslySetInnerHTML={{ __html: t('about_1961_1') }} />
              <BottomYear>1961</BottomYear>
            </div>
            <Box
              year={1964}
              image={images['year-1964-1']}
              text={t('about_1964_1')}
            />
            <Box
              year={1968}
              image={images['year-1968-1']}
              text={t('about_1968_1')}
            />
            <div>
              <Box
                year={1972}
                image={images['year-1972-1']}
                text={t('about_1972_1')}
                bottomYear={false}
              />
              <Box
                image={images['year-1972-2']}
                text={t('about_1972_2')}
                bottomYear={1972}
              />
            </div>
            <Box
              year={1973}
              image={images['year-1973-1']}
              text={t('about_1973_1')}
            />
            <Box
              year={1974}
              image={images['year-1974-1']}
              text={t('about_1974_1')}
            />
            <Box
              year={1975}
              image={images['year-1975-1']}
              text={t('about_1975_1')}
            />
            <Box
              year={1985}
              image={images['year-1985-1']}
              text={t('about_1985_1')}
            />
            <Box
              year={1987}
              image={images['year-1987-1']}
              text={t('about_1987_1')}
            />
            <div>
              <Box
                year={1994}
                image={images['year-1994-1']}
                bottomYear={false}
              />
              <Box
                image={images['year-1994-2']}
                text={t('about_1994_1')}
                bottomYear={1994}
              />
            </div>
            <Box
              year={1997}
              image={images['year-1997-1']}
              text={t('about_1997_1')}
            />
            <Box
              year={1998}
              image={images['year-1998-1']}
              text={t('about_1998_1')}
            />
            <Box
              year={1999}
              image={images['year-1999-1']}
              text={t('about_1999_1')}
            />
            <Box
              year={2001}
              image={images['year-2001-1']}
              text={t('about_2001_1')}
            />
            <Box
              year={2006}
              image={images['year-2006-1']}
              text={t('about_2006_1')}
            />
            <Box
              year={2008}
              image={images['year-2008-1']}
              text={t('about_2008_1')}
            />
            <Box
              year={2009}
              image={images['year-2009-1']}
              text={t('about_2009_1')}
            />
            <div>
              <Box
                year={2011}
                image={images['year-2011-1']}
                text={t('about_2011_1')}
                bottomYear={false}
              />
              <Box image={images['year-2011-2']} text={t('about_2011_2')} />
              <Box
                image={images['year-2011-3']}
                text={t('about_2011_3')}
                bottomYear={2011}
              />
            </div>
            <Box
              year={2012}
              image={images['year-2012-1']}
              text={t('about_2012_1')}
            />
          </StyledSlider>
        </SliderContainer>
      </Container>
    </>
  );
};

export default BrandStoryMb;
