import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import BrandStoryDt from './BrandStoryDt';
import BrandStoryMb from './BrandStoryMb';

import { getImages } from '../../utils/common';

const About = ({ data }) => {
  const { isMobileView } = useSelector((state) => state.common);

  const { t } = useTranslation();
  const sliderRef = React.createRef();
  const images = getImages(data.allFile.nodes);

  const [currentSlide, setCurrentSlide] = useState(0);

  function onYearItemClick(index) {
    sliderRef.current.slickGoTo(index);
  }

  return (
    <Layout
      fullwidth
      seoSettings={{ title: t('brand_story_meta_title') }}
      hasBreadcrumb={false}
    >
      {!isMobileView ? (
        <BrandStoryDt
          images={images}
          sliderRef={sliderRef}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          onYearItemClick={onYearItemClick}
        />
      ) : (
        <BrandStoryMb
          images={images}
          sliderRef={sliderRef}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          onYearItemClick={onYearItemClick}
        />
      )}
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "images/aboutUs" } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
