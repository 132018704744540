import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  YearList,
  Year,
  StyledSlider,
  SliderContainer,
  ImageContainer,
  Container,
} from './BrandStoryStyled';

import Img from 'gatsby-image';

const Box = ({
  year,
  text,
  textPosition = 'center',
  image,
  imageWidth = '240px',
  styles = {},
}) => {
  let className = 'align-middle';
  switch (textPosition) {
    case 'start':
      className = 'align-top';
      break;
    case 'end':
      className = 'align-bottom';
      break;
    default:
      break;
  }

  return (
    <div className="position-relative text-right">
      <Year>{year}</Year>
      <div className="m-auto" style={styles}>
        <Img
          fluid={image}
          style={{ width: imageWidth }}
          className={`d-inline-block  ${className}`}
        />
        <p
          className="d-inline-block text-left"
          style={{ marginLeft: '3rem', marginRight: '1rem', width: '156px' }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

const Box2 = ({
  year,
  text,
  textPosition = 'center',
  image,
  imageWidth = '339px',
  styles = {},
  textStyle = '',
}) => {
  let className = 'align-items-center';
  switch (textPosition) {
    case 'start':
      className = 'align-items-start';
      break;
    case 'end':
      className = 'align-items-end';
      break;
    default:
      break;
  }

  return (
    <div className="position-relative">
      <Year>{year}</Year>
      <div
        className={`row no-gutters justify-content-between ${className}`}
        style={{ paddingLeft: '0.5%', paddingRight: '0.5%', ...styles }}
      >
        <div style={{ width: imageWidth }}>
          <Img fluid={image} />
        </div>
        <p
          style={{ width: '170px', ...textStyle }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

const YearItem = ({ year, top, current, index, onClick }) => {
  return (
    <li
      className={`col text-center ${current === index ? 'active' : ''}`}
      style={{ top: top }}
      onClick={() => {
        onClick(index);
      }}
    >
      <div className={`dot `}></div>
      <div>{year}</div>
    </li>
  );
};

const BrandStoryDt = ({
  images,
  sliderRef,
  currentSlide,
  setCurrentSlide,
  onYearItemClick,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ImageContainer>
        <Img fluid={images['title']} />
      </ImageContainer>
      <SliderContainer>
        <Img fluid={images['bg-slider']} />
        <StyledSlider
          sliderRef={sliderRef}
          settings={{
            speed: 1500,
            dots: false,
            arrows: false,
            beforeChange: (current, next) => setCurrentSlide(next),
          }}
        >
          <div className="position-relative">
            <Year>1952</Year>
            <div
              className="row no-gutters flex-nowrap justify-content-between"
              style={{ marginTop: '50px', padding: '0.5%' }}
            >
              <div className="d-inline-block col-9">
                <Img
                  fluid={images['year-1952-1']}
                  style={{ width: '132px' }}
                  className="d-inline-block"
                />
                <Img
                  fluid={images['year-1952-2']}
                  style={{ width: '278px' }}
                  className="d-inline-block ml-3"
                />
                <p className="position-absolute">{t('about_1952_1')}</p>
              </div>

              <div style={{ width: '140px', marginTop: '50px' }}>
                <Img fluid={images['year-1952-3']} />

                <p>{t('about_1952_2')}</p>
              </div>
            </div>
          </div>

          <Box
            year={1957}
            image={images['year-1957-1']}
            text={t('about_1957_1')}
          />
          <div>
            <div
              className="position-relative"
              style={{
                width: '99%',
                margin: '0 auto',
              }}
            >
              <Year>1961</Year>
              <p dangerouslySetInnerHTML={{ __html: t('about_1961_1') }} />

              <div
                className="row no-gutters justify-content-between"
                style={{ marginTop: '50px' }}
              >
                <Img fluid={images['year-1961-1']} style={{ width: '173px' }} />
                <Img fluid={images['year-1961-2']} style={{ width: '173px' }} />
                <Img fluid={images['year-1961-3']} style={{ width: '173px' }} />
              </div>
            </div>
          </div>
          <Box
            year={1964}
            image={images['year-1964-1']}
            text={t('about_1964_1')}
          />
          <Box
            year={1968}
            image={images['year-1968-1']}
            text={t('about_1968_1')}
          />
          <div>
            <div
              className="position-relative"
              style={{
                width: '98%',
                margin: '0 auto',
              }}
            >
              <Year>1972</Year>
              <div className="row no-gutters">
                <div style={{ width: '183px' }}>
                  <p>{t('about_1972_1')}</p>
                  <Img fluid={images['year-1972-1']} />
                </div>
                <div style={{ width: '183px', marginLeft: '30px' }}>
                  <p>{t('about_1972_2')}</p>
                  <Img fluid={images['year-1972-2']} />
                </div>
              </div>
            </div>
          </div>

          <Box
            year={1973}
            image={images['year-1973-1']}
            text={t('about_1973_1')}
          />

          <Box2
            year={1974}
            image={images['year-1974-1']}
            text={t('about_1974_1')}
          />

          <Box
            year={1975}
            image={images['year-1975-1']}
            text={t('about_1975_1')}
          />

          <Box2
            year={1985}
            image={images['year-1985-1']}
            text={t('about_1985_1')}
            textPosition="end"
            textStyle={{ position: 'absolute', right: 0, top: '26%' }}
          />

          <Box
            year={1987}
            text={t('about_1987_1')}
            image={images['year-1987-1']}
          />
          <div>
            <div
              className="position-relative"
              style={{
                width: '98%',
                margin: '0 auto',
              }}
            >
              <Year>1994</Year>
              <p style={{ width: '400px', marginBottom: '40px' }}>
                {t('about_1994_1')}
              </p>
              <div className="row no-gutters justify-content-between">
                <div style={{ width: '285px' }}>
                  <Img fluid={images['year-1994-1']} />
                </div>
                <div style={{ width: '285px' }}>
                  <Img fluid={images['year-1994-2']} />
                </div>
              </div>
            </div>
          </div>

          <Box
            year={1997}
            text={t('about_1997_1')}
            image={images['year-1997-1']}
          />

          <Box2
            year={1998}
            image={images['year-1998-1']}
            text={t('about_1998_1')}
            textPosition="start"
            styles={{
              paddingTop: '70px',
            }}
          />

          <Box
            year={1999}
            image={images['year-1999-1']}
            text={t('about_1999_1')}
            styles={{ paddingTop: '70px' }}
            textPosition="start"
            imageWidth="336px"
          />

          <Box2
            year={2001}
            image={images['year-2001-1']}
            text={t('about_2001_1')}
            textPosition="start"
            styles={{
              paddingTop: '70px',
            }}
            imageWidth="385px"
          />

          <Box2
            year={2006}
            image={images['year-2006-1']}
            text={t('about_2006_1')}
            textPosition="start"
            styles={{
              paddingTop: '70px',
            }}
            imageWidth="385px"
          />

          <Box2
            year={2008}
            image={images['year-2008-1']}
            text={t('about_2008_1')}
            textPosition="start"
            styles={{
              paddingTop: '70px',
            }}
            imageWidth="385px"
          />

          <div className="position-relative">
            <Year>2009</Year>
            <p style={{ width: '400px', marginBottom: '40px' }}>
              {t('about_2009_1')}
            </p>
            <Img
              fluid={images['year-2009-1']}
              style={{ width: '98%', margin: '0 auto' }}
            />
          </div>

          <div className="position-relative">
            <Year>2011</Year>
            <div
              className="row no-gutters justify-content-between"
              style={{ padding: '0.5%', paddingTop: '80px' }}
            >
              <div style={{ width: '143px' }}>
                <Img fluid={images['year-2011-1']} />
                <p>{t('about_2011_1')}</p>
              </div>
              <div style={{ width: '234px' }}>
                <Img fluid={images['year-2011-2']} />
                <p>{t('about_2011_2')}</p>
              </div>
              <div style={{ width: '160px' }}>
                <Img fluid={images['year-2011-3']} />
                <p>{t('about_2011_3')}</p>
              </div>
            </div>
          </div>

          <div className="position-relative">
            <Year>2012</Year>
            <p style={{ width: '400px', marginBottom: '40px' }}>
              {t('about_2012_1')}
            </p>
            <Img
              fluid={images['year-2012-1']}
              style={{ width: '98%', margin: '0 auto' }}
            />
          </div>
        </StyledSlider>
      </SliderContainer>
      <div
        style={{
          width: '1000px',
          margin: '0 auto',
          paddingBottom: '60px',
        }}
      >
        <Img fluid={images['heritage-timeline']} />
        <YearList className="row no-gutters justify-content-between">
          <YearItem
            year={1952}
            top="-60px"
            current={currentSlide}
            index={0}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1957}
            top="-34px"
            current={currentSlide}
            index={1}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1961}
            top="-21px"
            current={currentSlide}
            index={2}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1964}
            top="-15px"
            current={currentSlide}
            index={3}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1968}
            top="-14px"
            current={currentSlide}
            index={4}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1972}
            top="-17px"
            current={currentSlide}
            index={5}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1973}
            top="-21px"
            current={currentSlide}
            index={6}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1974}
            top="-28px"
            current={currentSlide}
            index={7}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1975}
            top="-37px"
            current={currentSlide}
            index={8}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1985}
            top="-47px"
            current={currentSlide}
            index={9}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1987}
            top="-60px"
            current={currentSlide}
            index={10}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1994}
            top="-72px"
            current={currentSlide}
            index={11}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1997}
            top="-81px"
            current={currentSlide}
            index={12}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1998}
            top="-87px"
            current={currentSlide}
            index={13}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={1999}
            top="-91px"
            current={currentSlide}
            index={14}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={2001}
            top="-95px"
            current={currentSlide}
            index={15}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={2006}
            top="-97px"
            current={currentSlide}
            index={16}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={2008}
            top="-95px"
            current={currentSlide}
            index={17}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={2009}
            top="-86px"
            current={currentSlide}
            index={18}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={2011}
            top="-73px"
            current={currentSlide}
            index={19}
            onClick={(index) => onYearItemClick(index)}
          />
          <YearItem
            year={2012}
            top="-53px"
            current={currentSlide}
            index={20}
            onClick={(index) => onYearItemClick(index)}
          />
        </YearList>
      </div>
    </Container>
  );
};

export default BrandStoryDt;
