import styled from 'styled-components';
import { mediaQueries, calcRem } from '../../utils/mediaQueries';
import BgImage from '../../assets/images/aboutUs/bg-about-us.jpg';
import Slider from '../../components/Slider';

export const YearList = styled.ul`
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin: 0 -28px !important;

  li {
    position: relative;
    cursor: pointer;

    &.active .dot,
    &:hover .dot {
      width: 15px;
      height: 15px;
      background-color: #2a86ff;
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    background-color: #bac9cf;
    margin: 0 auto 15px;
    border-radius: 50%;
    transition: all 0.2s;
  }
`;

export const Year = styled.div`
  position: absolute;
  color: #496771;
  font-size: ${calcRem(65)};
  font-weight: bold;
  top: 0;
  right: 0;
  line-height: 1;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;

  ${mediaQueries('md')} {
    position: relative;
    right: auto;
    left: 0;
    font-size: 40px;
    margin-bottom: 10px;
  }
`;

export const StyledSlider = styled(Slider)`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 5%;
  width: 95%;

  ${mediaQueries('md')} {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    transform: none;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 615px;
  margin: ${calcRem(40)} auto 0;

  ${mediaQueries('md')} {
    width: 95%;

    .arrow {
      top: auto;
      bottom: 20px;
      transform: none;
      max-width: 14px;
      max-height: 40px;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 500px;
  margin: 0 auto;
`;

export const Container = styled.div`
  background: url(${BgImage}) no-repeat center;
  background-size: cover;
  color: #ffffff;
  ${mediaQueries('md')} {
    background-size: cover;
  }

  p {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
