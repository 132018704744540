import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ImgDtShadow from '../../assets/images/product/shadow.png';
import ImgMbShadow from '../../assets/images/global/pagetitle_shadow.jpeg';
import LayoutContext from '../../context/LayoutContext';
import { mediaQueries } from '../../utils/mediaQueries';

const Title = styled.h1`
  color: ${(props) => props.theme.prodcut.nameColor};
  font-weight: bold;
  padding: 10px;
  font-size: 35px;
  line-height: normal;

  @media screen and (min-width: 768px) {
    font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  }

  ${mediaQueries('md')} {
    font-size: 20px;
    text-transform: uppercase;
    border-bottom: 1px solid #b5b5b5;
  }
`;

const ImgShadowStyled = styled.img`
  max-width: 767px;
  /* padding-top: 25px;
  padding-bottom: 50px; */
  margin-left: auto;
  margin-right: 0;
  display: block;
`;

const PageTitle = ({ children, shadow = true, show }) => {
  const { isMobileView } = useSelector((state) => state.common);
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;

  const shadowImg = isMobileView ? ImgMbShadow : ImgDtShadow;

  return (
    <>
      {(isMobileView || show) && (
        <>
          <Title>{children}</Title>
          {shadow && gender !== 'men' && (
            <ImgShadowStyled
              src={shadowImg}
              alt="shadow"
              className="d-block m-auto"
            />
          )}
        </>
      )}
    </>
  );
};

export default PageTitle;
